<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <script type="application/javascript" src="https://js.stripe.com/v3/"></script>
    <v-card>
      <div v-if="user">
        <v-card v-if="isLoading" class="elevation-12 pa-4 text-center">
          <v-card-title class="headline d-block" style="">Preparing payment</v-card-title>
          <v-card-text class="">
            <p>One second, we're preparing the payment link...</p>
            <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
          </v-card-text>
        </v-card>

        <v-card-title class="subtitle">Make payment</v-card-title>
        <v-card-text>      
          <p>We use Stripe to safely and securely accept payments. Click Start to make your payment using Stripe.</p>
          <v-btn x-large color="primary" @click="pay">Start Payment</v-btn>
          <v-alert v-if="error" type="warning">{{error}}</v-alert>
        </v-card-text>   
      </div>    
      <div v-else>
        <v-alert type="info" tile>You'll need to login first.</v-alert>
        <v-card-text>
          <p>Please Login or Register to make the payment.</p>
          <v-btn color="accent" class="mr-4" :to="{ name: 'register', query: {referral: this.$route.query.referral, redirect:'checkout-pay' }}">Register</v-btn>
          <v-btn outlined color="accent" :to="{name: 'login', query: { referral: this.$route.query.referral, redirect:'checkout-pay' } }">Login</v-btn>
        </v-card-text>
      </div>  
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import checkoutService from "@/services/checkoutService";

export default {
  name: "Profile",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Checkout', disabled: true },
        { text: 'Cart', disabled: true },
      ],
      cart: null,
      isLoading: false,
      valid: false,
      error: null,
      //stripeSessionId: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.cart = store.getters.cart;
        console.log(this.cart);
        //this.stripeSessionId = response.data.id;
      }
    },
    async pay() {
      const cents = this.$route.query.amount ? parseFloat(this.$route.query.amount)*100 : parseInt(this.$route.query.cents);

      const request = {
        amount_cents: cents,
        currency: this.$route.query.currency,
        payment_methods: this.$route.query.pm,
      };
      this.isLoading = true;
      const response = await checkoutService.createPaymentSession(request);
      this.valid = true;
      this.isLoading = false;

      var stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      const stripeResponse = stripe.redirectToCheckout({ sessionId: response.data.id });
      if (stripeResponse.error) {
        this.error = stripeResponse.error;
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

